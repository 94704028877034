@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: Dance;
  src: url(../assets/Webfonts/BasicDanceDemo-nRKZ4.ttf);
}

@font-face {
  font-family: descriptive;
  src: url(../assets/Webfonts/CoffeeHealingItalic-RpP6o.ttf);
}

@font-face {
  font-family: Typewriter;
  src: url(../assets/Webfonts/Aloevera.ttf);
}

@font-face {
  font-family: Nationals;
  src: url(../assets/Webfonts/ApesTitle-11D0.ttf);
}

* {
  margin: 0;
  padding: 0;
}

.app {
  background-image: url(../assets/bg.jpg);
  background-position: center;
  background-size: cover;
  position: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app::-webkit-scrollbar {
  display: none;
  width: 0px;
}
