@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

:root {
  /* Colors */
  --brand-color: hsl(46, 100%, 50%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  /* Fonts */
  --font-title: "Montserrat", sans-serif;
  --font-text: "Lato", sans-serif;
}

/* RESET */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */

h2,
p {
  margin: 0;
}

.cardtitle {
  font-size: 20px;
  font-family: var(--font-title);
  color: var(--white);
  line-height: 1.1;
}

.carddesc {
  padding-top: 10px;
  font-family: var(--font-text);
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white);
}

.card {
  margin: 20px;
  display: grid;
  min-width: 150px;
  max-width: 250px;
  width: 80%;
  height: auto;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}

.card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.cardbackground {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.cardcontent1 {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 55%;
  padding: 11% 1.25rem 1.875rem;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.3) 10%,
    hsl(0, 0%, 0%) 100%
  );
}

.cardcontent2 {
  --flow-space: 1.25rem;
}

.cardtitle {
  position: relative;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.cardtitle::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--brand-color);
}

@media (any-hover: hover) and (any-pointer: fine) {
  .cardcontent1 {
    transform: translateY(62%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .cardtitle::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .cardbackground {
    transition: transform 500ms ease-in;
  }

  .cardcontent2 > :not(.cardtitle),
  .cardbutton {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  .card:hover,
  .card:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  }

  .card:hover .cardcontent1,
  .card:focus-within .cardcontent2 {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .card:focus-within .cardcontent1 {
    transition-duration: 0ms;
  }

  .card:hover .cardbackground,
  .card:focus-within .cardbackground {
    transform: scale(1.3);
  }

  .card:hover .cardcontent2 > :not(.cardtitle),
  .card:hover .card:focus-within .cardcontent2 > :not(.cardtitle),
  .card:focus-within .cardbutton {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .card:hover .cardtitle::after,
  .card:focus-within .cardtitle::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
}
