@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

/* * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

body {
	display: flex;
	background: #000;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
} */

.content {
  width: 100%;
  /* float: right; */
  /* margin-top: 150px; */
  margin-right: 400px;
  position: relative;
  margin-bottom: 0;
}

.content h2 {
  color: #fff;
  font-size: 150px;
  position: absolute;
  text-align: center;
  /* margin: 0 auto; */
  /* transform: translate(-50%, -50%); */
}

.content h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #03a9f4;
  /* text-shadow: black 0px 0px 30px; */
}

.content h2:nth-child(2) {
  color: #03a9f4;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

.nationals {
  display: none;
}

.canvas {
  display: flex;
  /* height: 800px; */
  /* flex-direction: row; */
  /* margin-top: 100px; */
  /* width: 80%; */
  align-items: center;
  justify-content: center;
}

.typewriterContainer {
  color: #ffffff;

  width: 400px;
  height: 150px;
  margin-top: 450px;
  padding: 0;
}

.typewriter {
  /* margin-right: 00px; */
  font-size: 6rem;
  /* letter-spacing: 0; */
  margin-right: 500px;
}

.typewriterCursor {
  color: whitesmoke;
  text-shadow: black 0px 0px 10px;
}

.typed1 {
  color: #09e38f;
  text-shadow: black 0px 0px 10px;
}
.typed2 {
  color: #cfad31;
  text-shadow: black 0px 0px 10px;
}

.typed-3 {
  color: rgb(254, 34, 70);
  text-shadow: black 0px 0px 10px;
}

@media screen and (max-width: 1200px) {
  .typewriter {
    font-size: 5rem;
  }
}

@media screen and (max-width: 920px) {
  .typewriter {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 850px) {
  /* .nationals {
    display: inline-block;
    font-size: 7rem;
  } */
  .content {
    display: none;
  }

  .typewriterContainer {
    margin-top: 300px;
    width: 70vw;
  }

  .typewriter {
    font-size: 13vw;
  }
}

@media screen and (max-width: 700px) {
  .typewriter {
    font-size: 15vw;
  }
}

@media screen and (max-width: 550px) {
  /* .typewriterContainer {
    margin-top: 350px;
    width: 80vw;
  } */

  .typewriter {
    font-size: 16vw;
  }
}

@media screen and (max-width: 400px) {
  .typewriterContainer {
    width: 90vw;
  }

  .typewriter {
    font-size: 18vw;
  }
}
