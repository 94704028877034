@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");

.aboutus {
  color: #ffffff;
  width: 80%;
  margin: 20px;
  margin-top: 30vh;
  font-family: "Dancing Script", cursive;
  box-sizing: border-box;
  padding: 20px;
  /* From https://css.glass */
  /* background: rgba(91, 91, 91, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
  border: 1px solid rgba(91, 91, 91, 0.48); */
  background-image: linear-gradient(147deg, #545b62a8, black);
  border-radius: 8px;
  box-shadow: 0 0px 20px rgb(110, 109, 109, 0.3);
}

.aboutus h2 {
  font-family: Dance;
  font-size: 50px;
}

.abouttitle {
  text-align: center;
  font-size: 40px;
}

/* .abouttext {
  text-align: center;
  margin: 15px 0px 0px 0px;
  font-size: 30px;
  font-family: descriptive;
  font-weight: 100;
} */

.abouttext {
  margin: 15px 0px 0px 0px;
  font-size: 25px;
  font-family: poppins;
  font-weight: 400;
  line-height: 35px;
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .abouttext {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
  }
}
