@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

/* @font-face {
  font-family: Dance;
  src: url(../assets/Webfonts/BasicDanceDemo-nRKZ4.ttf);
} */

.links {
  box-sizing: border-box;
  padding-right: 20px;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
}

.mainbar {
  /* From https://css.glass */
  background: rgba(91, 91, 91, 0.46);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
  border: 1px solid rgba(91, 91, 91, 0.48);
  position: fixed;
  z-index: 10;
  height: 60px;
}

.title {
  font-size: 20px;
  color: white;
  margin-left: 20px;
  text-decoration: solid;
  /* letter-spacing: 2px; */
  font-weight: 100;
  font-family: poppins;
  /* font-family: Nationals; */
  letter-spacing: 2px;
  cursor: pointer;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  position: fixed;
  color: white;
}

nav a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;
}

nav a:hover {
  color: grey;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media (max-width: 1000px) {
  .links {
    display: none;
  }
}
