@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
.schedule {
  margin: 20px;
  width: 80%;
  box-sizing: border-box;
  padding: 20px;
  /* From https://css.glass */
  /* background: rgba(91, 91, 91, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
  border: 1px solid rgba(91, 91, 91, 0.48); */
  background-image: linear-gradient(147deg, #545b62a8, black);
  border-radius: 8px;
  box-shadow: 0 0px 20px rgb(110, 109, 109, 0.3);
  font-family: "Satisfy", cursive;
}

/* .schedule h2 {
  font-family: Dance;
  font-size: 50px;
} */

.cardst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.citiestitle {
  /* font-family: "Dancing Script", cursive; */
  text-align: center;
  font-weight: 800;
  color: whitesmoke;
  /* font-size: 35px; */
  font-family: Dance;
  font-size: 50px;
}
.titlecard {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}
