#cities {
  display: flex;
  flex-direction: column;
  /* background: #1d1f32; */
}

@media (650px <= width <= 1170px) {
  #cities {
    padding: 0 30%;
  }
}

@media (650px <= width <= 800px) {
  #cities {
    padding: 0 20%;
  }
}

@media (width < 650px) {
  #cities {
    padding: 0 10%;
  }
}

@media (width < 550px) {
  #cities {
    padding: 0 2%;
  }
}

/* .css-1sj2t5c {
  width: 110%;
} */

.timeline-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.timeline-img {
  width: 80%;
  height: 80%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 8%;
}

/*   */

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
.schedule {
  margin: 20px;
  width: 80%;
  box-sizing: border-box;
  padding: 20px;
  /* From https://css.glass */
  background: rgba(91, 91, 91, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
  border: 1px solid rgba(91, 91, 91, 0.48);
  font-family: "Satisfy", cursive;
}

/* .schedule h2 {
  font-family: Dance;
  font-size: 50px;
} */

.cardst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.cardst h1 {
  font-style: poppins !important;
}

.citiestitle {
  /* font-family: "Dancing Script", cursive; */
  text-align: center;
  font-weight: 800;
  color: whitesmoke;
  /* font-size: 35px; */
  font-family: Dance;
  font-size: 50px;
}
.titlecard {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}
