/* .contacts {
  color: #ffffff;
  margin: 20px;
  width: 80%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
}

.contacttitle {
  text-align: center;
  font-size: 50px;
  margin: 10px 0px 25px 0px;
  font-family: Dance;
  letter-spacing: 2px;
}

.contactcards {
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */

/* .contact-h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
} */

.page-title {
  text-align: center;
  font-size: 50px;
  margin: 10px 0px 5px 0px;
  font-family: Dance;
  letter-spacing: 2px;
  color: white;
}

.contact-us-page {
  /* background: rgba(0, 0, 0, 0.8);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(17.6px);
  -webkit-backdrop-filter: blur(17.6px); */
  padding-bottom: 5%;
  /* padding-top: 5%; */
  width: 100%;
}

.contactCard-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em;
  padding-top: 6rem;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 80%;
  /* background: rgba(91, 91, 91, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
  border: 1px solid rgba(91, 91, 91, 0.48); */
  background-image: linear-gradient(147deg, #545b62a8, black);
  border-radius: 8px;
  box-shadow: 0 0px 20px rgb(110, 109, 109, 0.3);
  margin: 0 auto;
}
