.typed1 {
  color: #09e38f;
  text-shadow: black 0px 0px 10px;
  padding: 0;
}
.typed2 {
  color: #cfad31;
  text-shadow: black 0px 0px 10px;
  padding: 0;
}

.typed3 {
  color: rgb(254, 34, 70);
  text-shadow: black 0px 0px 10px;
  padding: 0;
}

.canvas_typewriterCursor__UU3kQ {
  margin: 0;
}

@media screen and (max-width: 850px) {
  /* .typewriterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 100vw;
  } */

  /* .typewriterContainer h1 {
    text-align: center;
    margin: 0 auto;
  } */
  .typed1 {
    font-family: Typewriter;
  }

  .typed2 {
    font-family: Typewriter;
  }

  .typed3 {
    font-family: Typewriter;
  }
}
