@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");

.maintitle {
  text-align: center;
  font-size: 50px;
  margin-top: 50px;
  color: #ffffff;
  /* font-family: "Dancing Script", cursive; */
  font-family: Dance;
}

.eventsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event {
  margin: 20px;
  box-sizing: border-box;
  padding: 40px;
  color: #ffffff;
  width: 80%;
  font-family: "Dancing Script", cursive;
  /* From https://css.glass */
  /* background: rgba(91, 91, 91, 0.46);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.4px);
  -webkit-backdrop-filter: blur(9.4px);
  border: 1px solid rgba(91, 91, 91, 0.48); */
  background-image: linear-gradient(147deg, #545b62a8, black);
  border-radius: 8px;
  box-shadow: 0 0px 20px rgb(110, 109, 109, 0.3);
}
.eventstitle {
  text-align: center;
  font-size: 40px;
  font-family: Dance;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.eventssubtitle {
  /* text-align: center; */
  font-size: 25px;
  font-family: poppins;
  font-weight: 600;
}

.eventstext {
  text-align: left;
  font-size: 20px;
  font-family: poppins;
}

.event ul {
  margin: 0px;
  padding: 0px;
  /* display: flex; */
}

.event li {
  margin: 0px;
  padding: 0px;
}
